import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Head = ({ title }) => {
  const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)
  return (
    <Helmet title={`${title} | ${data.site.siteMetadata.title}`}>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
      <meta name="description" content="Emre Ekener, freelance web developer who loves to build nice looking web spaces." />
      <link rel="apple-touch-icon-precomposed" sizes="57x57" href="apple-touch-icon-57x57.png" />
      <link rel="apple-touch-icon-precomposed" sizes="114x114" href="apple-touch-icon-114x114.png" />
      <link rel="apple-touch-icon-precomposed" sizes="72x72" href="apple-touch-icon-72x72.png" />
      <link rel="apple-touch-icon-precomposed" sizes="144x144" href="apple-touch-icon-144x144.png" />
      <link rel="apple-touch-icon-precomposed" sizes="60x60" href="apple-touch-icon-60x60.png" />
      <link rel="apple-touch-icon-precomposed" sizes="120x120" href="apple-touch-icon-120x120.png" />
      <link rel="apple-touch-icon-precomposed" sizes="76x76" href="apple-touch-icon-76x76.png" />
      <link rel="apple-touch-icon-precomposed" sizes="152x152" href="apple-touch-icon-152x152.png" />
      <link rel="icon" type="image/png" href="favicon-196x196.png" sizes="196x196" />
      <link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96" />
      <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32" />
      <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16" />
      <link rel="icon" type="image/png" href="favicon-128.png" sizes="128x128" />
      <meta name="application-name" content="&nbsp;"/>
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content="mstile-144x144.png" />
      <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
      <meta name="msapplication-square150x150logo" content="mstile-150x150.png" />
      <meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
      <meta name="msapplication-square310x310logo" content="mstile-310x310.png" />

       {/*<!-- Open Graph / Facebook -->*/}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://ekener.io/" />
    <meta property="og:title" content="Home | Ekener Development" />
    <meta property="og:description" content="Emre Ekener, freelance web developer who loves to build nice looking web spaces." />
    <meta property="og:image" content="https://ekener.io/facebookimage.png" />

          {/*<!-- Twitter -->*/}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://ekener.io/" />
    <meta property="twitter:title" content="Home | Ekener Development" />
    <meta property="twitter:description" content="Emre Ekener, freelance web developer who loves to build nice looking web spaces." />
    <meta property="twitter:image" content="https://ekener.io/twitterimage.png" />

    </Helmet>
  )
}

export default Head
