import React from 'react'
import {I18nextContext, Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';

import headerStyles from './header.module.scss'

import German from '../icons/german.svg'
import English from '../icons/english.svg'
import Logo from '../images/logo.png'

const Header = () => {
  const { language } = React.useContext(I18nextContext)
  const { originalPath } = useI18next()
  return (
    <header className={headerStyles.navbarContainer}>
    <Link to="/">
      <img className={headerStyles.logo} src={Logo} alt="logo" />
    </Link>
      <nav className={headerStyles.navbar}>
          <Link to="/"><Trans>Home</Trans></Link>
          <Link to="/services"><Trans>Services</Trans></Link>
          <Link to="/projects"><Trans>Projects</Trans></Link>
      </nav>
        <div className={headerStyles.langSwitcher}>
          {language === "en" ? <Link to={originalPath} language={"de"}><German /></Link> : <Link to={originalPath} language={"en"}><English /></Link>}
        </div>
        <div className={headerStyles.mobileNavigation}>
         <input type="checkbox" className={headerStyles.navigationCheckbox} id="navi-toggle" />
         <label htmlFor="navi-toggle" className={headerStyles.navigationButton} >
          <span className={headerStyles.navigationIcon}>&nbsp;</span>
          </label>
          <div className={headerStyles.navigationBackground}>&nbsp;</div>
          <nav className={headerStyles.navigationNav}>
              <ul className={headerStyles.navigationList}>
                <li className={headerStyles.navigationItem}>
                  <Link to="/" className={headerStyles.navigationLink}><Trans>Home</Trans></Link>
                </li>
                <li className={headerStyles.navigationItem}>
                  <Link to="/services" className={headerStyles.navigationLink}><Trans>Services</Trans></Link>
                </li>
                <li className={headerStyles.navigationItem}>
                  <Link to="/projects" className={headerStyles.navigationLink}><Trans>Projects</Trans></Link>
                </li>
                <li className={headerStyles.navigationItem}>
                  {language === "en" ? <Link to={originalPath} language={"de"}><German /></Link> : <Link to={originalPath} language={"en"}><English /></Link>}
                </li>
                <li className={headerStyles.navigationItem}>
                </li>
              </ul>
          </nav>
        </div>
    </header>
  )
}

export default Header

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;