import React from 'react'
import {Link, Trans} from 'gatsby-plugin-react-i18next';


import contactStyle from './contact.module.scss'

const Contact = () => {
  return (

    <div className={contactStyle.contactLinks}>

        <span>
          <a  rel="noopener noreferrer" aria-label="Email" target="_blank" href="mailto:emre@ekener.io">
            emre@ekener.io
          </a>
        </span>
        <span>
          <Link to="/imprint"><Trans>Imprint</Trans></Link>
        </span>
        <span>
          <Link to="/privacy"><Trans>Privacy Policy</Trans></Link>
        </span>
      </div>




  )
}

export default Contact
