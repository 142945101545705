import React from 'react'

import footerStyles from './footer.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faXingSquare } from '@fortawesome/free-brands-svg-icons'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  return (
    <footer className={footerStyles.footer}>
    <div className={footerStyles.footerLogos}>
      <p>
          <a target="__blank" aria-label="Github" href="https://github.com/eekener">
            <FontAwesomeIcon icon={faGithub}/>
          </a>
          <a target="__blank" aria-label="Linkedin" href="https://www.linkedin.com/in/emre-ekener-396a31148/">
            <FontAwesomeIcon icon={faLinkedin}/>
          </a>
          <a target="__blank" aria-label="Xing" href="https://www.xing.com/profile/Emre_Ekener">
            <FontAwesomeIcon icon={faXingSquare}/>
          </a>
          <a target="__blank" aria-label="Twitter" href="https://twitter.com/windowshopperez">
            <FontAwesomeIcon icon={faTwitterSquare}/>
            </a>
        </p>
    </div>
      <span>Emre Ekener © {new Date().getFullYear()}</span>
    </footer>
  )
}

export default Footer
