import React from 'react'

import '../scss/index.scss';
import '../scss/_breakpoints.scss';
import '../scss/_reset.scss';
import layoutStyles from './layout.module.scss'

import Header from '../components/header'
import Footer from '../components/footer'

const Layout = (props) => {
  return (
    <div className="container-lg">
      <div className={layoutStyles.content}>
        <Header />
        {props.children}
      </div>
      <Footer />
    </div>
  )
}


export default Layout
